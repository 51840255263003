<template>
  <div class="main-content-container container-fluid h-100 px-4">
    <b-row no-gutters>
      <b-col class="mx-auto" lg="3" md="5" style="margin-top: 2vw !important;">
        <b-card>
          <b-card-body>
            <h2 class="text-danger text-center mb-4">{{ $t('close_sessions.title') }}</h2>
            <div class="text-dark">
              <h4>{{ $t('close_sessions.info_msg') }}</h4>
            </div>
            <b-button v-if="showButton" block-level="full" variant="danger" @click="sendMail">{{$t('close_sessions.send_mail')}}</b-button>
          </b-card-body>
        </b-card>
        <div class="auth-form__meta justify-content-around d-flex mt-4">
          <b-link href="/forgot-password">{{ $t('login.forgot_password') }}</b-link>
          <b-link href="/login">Login</b-link>
          <b-link href="/register">{{ $t('login.new_account') }}</b-link>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {decrypt} from '@/helpers/encrypt';
import {post} from '@/services/api';

export default {
  name: 'CloseSessions',
  data: () => ({
    params: null,
    showButton: true
  }),
  methods: {
    sendMail() {
      this.showButton = false
      post('auth/recovery-password', this.params)
        .then(() => {
          this.$swal({
            showCancelButton: false,
            showConfirmButton: true,
            title: 'An email has been sent to your email address.',
            confirmButtonColor: '#d14343',
            cancelButtonColor: '#000000',
            cancelButtonText: 'No',
            confirmButtonText: 'OK',
          }).then(async res => {
            if (res.isConfirmed) {
              this.$router.push('/')
            }
          });
        })
    }
  },
  mounted() {
    this.params = JSON.parse(decrypt(this.$route.params.token));
    post('auth/invalidate-tokens', this.params)
      .then()
      .catch(e => console.log(e));
  }
};
</script>

<style scoped>

</style>
